import {http_client} from "@/plugins/http_client";

const url = 'public';

const getSeguimientoSIP = async (params = {}) => await http_client(`/${url}/reportes-sip`, params, 'get')
const getStateReportes = async (params = {}) => await http_client(`/${url}/estados-reportes`, params, 'get')
export default {
  getSeguimientoSIP,
  getStateReportes
}
