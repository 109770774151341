export const actasRouter = [
  {
    path: "/generacion_actas_proveedor",
    name: "generacionActasProveedor",
    meta: { rol: "ROL_ACTAS_PROVEEDOR_LISTAR_INFORMES" },
    component: () =>
        import(/* webpackChunkName: "generacion_actas" */ "@/views/generacion_actas/actas_proveedor/index.vue"),
  },
  {
    path: "/generacion_actas_SIP",
    name: "generacionActasSIP",
    meta: { rol: "ROL_ACTAS_SIP_LISTAR_INFORMES" },
    component: () =>
        import(/* webpackChunkName: "generacion_actas" */ "@/views/generacion_actas/actas_sip/index.vue"),
  },
]
