export const proveedorRoutes = [
  {
    path: "/equipo-proveedor",
    name: "equipo-proveedor",
    meta: { rol: "ROL_PROVEEDOR_LISTAR_INFORMES" },
    component: () =>
      import(/* webpackChunkName: "equipo-proveedor" */ "../index.vue"),
  },
  {
    path: "/detalle-informe-proveedor/:id",
    name: "detalleInformeProveedor",
    meta: { rol: "ROL_PROVEEDOR_VER_DETALLE_GENERAL_INFORME" },
    component: () =>
      import(/* webpackChunkName: "crear-informe" */ "../views/FormInformeView.vue"),
  },
]
