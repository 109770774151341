import {http_client} from "../plugins/http_client";

const url = 'public/proveedor/';
const uri = 'public'

const getInfoGeneral = async (id, params ={}) => await http_client(`${url}detalle-general/${id}`, params)
const getMedioConexion = async (params = {}) => await http_client(`${uri}/medio-conexion`, params)
const getTipoMaterial = async (params = {}) => await http_client(`${uri}/tipo-material`, params)
const getUnidadMedida = async (params = {}) => await http_client(`${uri}/unidad-medida`, params)
const getListInforme = async (id,params = {}) => await http_client(`${url}detalle-informe/listado/${id}`, params, "get")
const setDetalleInforme = async (params ={}) => await http_client(`${url}detalle-informe`, params, 'post')
const putDetalleInforme = async (id, params ={}) => await http_client(`${url}detalle-informe/${id}`, params, 'put')
const getDetalleInformeEquipo = async (id, params ={}) => await http_client(`${url}detalle-informe/ver/${id}`, params)
const detalleItemTable = async (id, params ={}) => await http_client(`${url}detalle-general/listado/${id}`, params)
const deleteItemTable = async (id, params ={}) => await http_client(`${url}detalle-informe/eliminar/${id}`, params, 'delete')


export default {
    getInfoGeneral,
    getMedioConexion,
    getListInforme,
    getTipoMaterial,
    setDetalleInforme,
    putDetalleInforme,
    getUnidadMedida,
    detalleItemTable,
    deleteItemTable,
    getDetalleInformeEquipo
}
