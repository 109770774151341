<template>
  <v-chip small :color="getColorState" class="text-caption text-truncate" :class="`${getColorTextState}--text`">
    {{ textState }}
  </v-chip>
</template>
<script>
export default {
  name: "AppStateTable",
  props: {
    state: {
      type: Number,
    },
    textStateInstalacion: {
      type: String,
      default: "",
    },
    isSoliState: {
      type: Boolean,
      default: false,
    },
    isRedes: {
      type: Boolean,
      default: false,
    },
    isInstalacion: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    textState() {
      if (this.isSoliState) {
        if (this.isRedes && this.state === 3) {
          return this.soliState[6];
        } else if (this.isInstalacion && this.state === 7) {
          return this.textStateInstalacion;
        }
        return this.soliState[this.state];
      } else {
        return this.otherState[this.state];
      }
    },
    getColorTextState() {
      if (this.isSoliState) {
        if (this.isRedes && this.state === 3) {
          return this.textColorSoliState[6];
        } else if (this.isRedes && this.state === 7) {
          return this.textColorSoliState[5];
        } else if (this.isInstalacion && this.state === 7) {
          return this.textStateInstalacion === 'Verificado' ? this.textColorSoliState[5] : this.textColorSoliState[2];
        }
        return this.textColorSoliState[this.state];
      } else {
        return this.textColorState[this.state];
      }
    },
    getColorState() {
      if (this.isSoliState) {
        if (this.isRedes && this.state === 3) {
          return this.colorSoliState[6];
        }
        if (this.isRedes && this.state === 7) {
          return this.colorSoliState[5];
        } else if (this.isInstalacion && this.state === 7) {
          return this.textStateInstalacion === 'Verificado' ? this.colorSoliState[5] : this.colorSoliState[2];
        }
        return this.colorSoliState[this.state];
      } else {
        return this.colorState[this.state];
      }
    }
  },
  data() {
    return {
      soliState: {
        1: "En borrador",
        2: "Observado",
        3: "Enviado",
        4: "Solventado",
        5: "Aprobado",
        6: "Pendiente",
        7: "Verificado",

      },
      colorSoliState: {
        1: "stateEnBorrador",
        2: "stateObservado",
        3: "stateEnviado",
        4: "stateSolventadoRGB",
        5: "stateAprobado",
        6: "stateEnBorrador",
        7: "stateObservado",

      },
      textColorSoliState: {
        1: "textEnBorrador",
        2: "textObservado",
        3: "textEnviado",
        4: "textSolventado",
        5: "textAprobado",
        6: "textEnBorrador",
        7: "textObservado",

      },
      colorState: {
        1: "stateAprobado",
        2: "stateInactivoRgb",
      },
      textColorState: {
        1: "textAprobado",
        2: "textInactivo",
      },
      otherState: {
        1: "Activo",
        2: "Inactivo",
      }
    };
  }
};
</script>
<style scoped lang="scss">
.stateSolventadoRGB{
  color: var(--v-stateSolventado-base) !important;
}

.stateInactivoRgb{
  color: var(--v-stateInactivo-base) !important;
}
</style>
