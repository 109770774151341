export const reportesRouter = [
  {
    path: "/reportes-MINED",
    name: "reportesMINED",
    meta: { rol: "ROL_REPORTE_MINED_LISTAR_INFORMES" },
    component: () =>
        import(/* webpackChunkName: "profiles" */ "@/views/reportes/reportesMINED/index.vue"),
  },
  {
    path: "/seguimiento-SIP",
    name: "seguimientosSIP",
    meta: { rol: "ROL_SEGUIMIENTO_SIP_LISTAR_INFORMES" },
    component: () =>
        import(/* webpackChunkName: "profiles" */ "@/views/reportes/seguimientoSIP/index.vue"),
  },

]
