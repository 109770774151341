<template>
	
	<v-row class="mt-8 align-stretch justify-space-between" :no-gutters="pantallaPequena">
		<v-col cols="12" md="5">
			<label for="buscar">Buscar</label>
			<v-text-field
				v-model="textBusqueda"
				name="buscar"
				placeholder="Buscar por nombre"
				outlined
				dense
				clearable
			/>
		</v-col>
		<v-col cols="12" md="5" :class=" !pantallaPequena ? 'd-flex align-center' : ''">
			<v-btn
				color="primary"
				outlined
				class="text-capitalize"
				:class="pantallaPequena ? 'mb-5' : 'mr-5'"
				:block="pantallaPequena"
				@click="buscarSitio"
				:disabled="textBusqueda === ''"
			>Buscar</v-btn>
			
			<v-btn
				color="grey darken-1"
				outlined
				class="text-capitalize"
				:block="pantallaPequena"
				:disabled="contadorBusqueda === 0"
				@click="limpiarFilros"
			>Limpiar</v-btn>
		</v-col>
		<v-col cols="12" md="2" :class="pantallaPequena && 'mt-4'" class="d-flex align-center justify-end">
			<slot name="contentAction"></slot>
		</v-col>
	</v-row>
	
</template>

<script>
	export default {
		data() {
			return {
				textBusqueda: '',
				contadorBusqueda: 0
			}
		},
		
		computed: {
			pantallaPequena() {
				return this.$vuetify.breakpoint.smAndDown;
			}
		},
		
		methods: {
			
			buscarSitio() {
				this.contadorBusqueda++;
				this.$emit('filtrar', this.textBusqueda);
			},
			
			limpiarFilros() {
				this.contadorBusqueda = 0;
				this.textBusqueda = '';
				this.$emit('limpiarFiltros');
			},
		}
	}
</script>