<template>
  <v-app-bar app :elevation="0" color="mediumBlue" dense>
    <v-app-bar-nav-icon style="background-color: white; border-radius: 0px 15px 0px 0px;" @click.stop="setSideBar(!sideBar)"></v-app-bar-nav-icon>
    <v-spacer></v-spacer>

    <v-menu offset-y transition="scroll-y-transition" rounded="lg">
      <template v-slot:activator="{ on, attrs }">

        <!-- {{ userInfo.user.email }} -->
        <v-avatar
            color="white"
            size="36"
            v-bind="attrs" v-on="on"
        >
          <span class="mediumBlue--text text-h5">{{ getUserInfo }}</span>
        </v-avatar>

      </template>
      <v-list dense nav>
        <!--        <v-list-item :to="{name:'profile'}" class="my-0">-->
        <!--          <v-list-item-icon>-->
        <!--            <v-icon size="20">mdi-account</v-icon>-->
        <!--          </v-list-item-icon>-->
        <!--          <v-list-item-title>Perfil</v-list-item-title>-->
        <!--        </v-list-item>-->
        <!--        <v-list-item :to="{name:'security'}" class="my-0">-->
        <!--          <v-list-item-icon>-->
        <!--            <v-icon size="20">mdi-account</v-icon>-->
        <!--          </v-list-item-icon>-->
        <!--          <v-list-item-title>Seguridad</v-list-item-title>-->
        <!--        </v-list-item>-->
        <v-list-item @click="cerrarSession()" class="my-0">
          <v-list-item-icon>
            <v-icon size="20">mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Cerrar Sesión</v-list-item-title>
        </v-list-item>
        <!--  s-->
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "AppHeader",
  methods: {
    ...mapMutations("utils", ["setSideBar", "setMenu"]),
    ...mapMutations(["setToken"]),
    async cerrarSession() {

      const {status} = await this.services.auth.cerrarSesion()
      if (status === 200) {
        this.setMenu([]);
        this.setToken(null);
        localStorage.clear();
        await this.$router.push({name: 'login'})
      }

    },
  },

  computed: {
    ...mapState(["userInfo"]),
    ...mapState("utils", {
      sideBar: "sideBar",
      getUserInfo(){
        const user= JSON.parse(localStorage.getItem('userInfo') )
        return user.inicial_usuario.toUpperCase()       
      }

    }),

  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-toolbar__content, .v-toolbar__extension{
  padding: 4px 12px !important;
}
</style>
