<template>
  <v-container fluid>
    <v-row class="justify-center mx-md-6">
      <v-col cols="12" md="7" class="d-flex flex-column">
        <span class="text-h4 boldBlue--text"> {{ title }} </span>
        <span class="text-h5 boldBlue--text"> {{ subtitle }} </span>
      </v-col>
      <v-col cols="12" md="3" class="d-flex justify-center justify-md-start">
        <v-btn
            class="text-no-style grey--text text--darken-2 elevation-0 pa-6"
            color="grey lighten-2"
            @click="$router.go(-1)"
        >
          Regresar
        </v-btn>
        <v-btn
            v-if="!hiddenBtns"
            class="text-no-style mx-4 pa-6"
            color="lightBlue"
            outlined
            @click="$emit('guardar')"
        >
          Guardar
        </v-btn>
        <v-btn
            v-if="!hiddenBtns"
            class="text-no-style white--text elevation-0  pa-6"
            color="lightBlue"
            :disabled="disabledBtnEnviar"
            @click="$emit('enviar')"
        >
          Enviar
        </v-btn>
      </v-col>

      <v-col cols="12" class="margin-md">
        <h3 class="boldBlue--text">{{ `${textCorrelativo}` }}</h3>
      </v-col>

    </v-row>

  </v-container>
</template>

<script>
export default {
  name: "HeaderInformComponent",

  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    disabledBtnEnviar: {
      type: Boolean,
      default: true,
    },
    hiddenBtns: {
      type: Boolean,
      default: true,
    },
    correlativo: {
      type: String,
      default: "",
    }
  },
  computed: {
    textCorrelativo() {
      if (this.correlativo === "") return ""
      return `Informe de conectividad ${this.correlativo}`
    }
  },
  watch: {
    disabledBtnEnviar: {
      handler(newVal) {
        this.disabledBtnEnviar = newVal;
      },
      deep: true,
      inmediate: true
    }
  }
};
</script>
<style lang="scss" scoped>
.margin-md {
  padding-left: 10%;
}

.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
  background-color: rgba(55, 88, 249, 0.5) !important;
  color: white !important;
}

</style>
