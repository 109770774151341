import { http_client } from "@/plugins/http_client";

const baseUrl = '/public';
const uploadFile = async (body) =>   await http_client(`${baseUrl}/upload-archivo`, body, 'post');
const downloadFile = async (id_informe, data) =>  await http_client(`${baseUrl}/download-archivo/${id_informe}`, data, 'get');
const deleteFile = async (id_informe, id_tipo_archivo) =>  await http_client(`${baseUrl}/eliminar-archivo/${id_informe}/${id_tipo_archivo}`, {}, 'delete');
export default {
  uploadFile,
  downloadFile,
  deleteFile
};
