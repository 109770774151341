<template>
  <v-navigation-drawer
      :value="sideBar"
      fixed
      app
      @input="setSideBar($event)"
  >
    <template v-slot:prepend>
<!--      !sideBar-->
      <v-card elevation="2" class="ma-4 py-0" v-if="false">
        <v-card-title>
          <img
              :src="require(`@/assets/img/SIS-HAZUL.png`)"
              width="95%"
              height="auto"
          />
        </v-card-title>
      </v-card>
      <v-list-item v-else two-line>
        <v-btn text icon x-large color="blueMinsal"
        >
          <v-icon size="30">mdi-asterisk</v-icon>
        </v-btn
        >
      </v-list-item>
    </template>

    <v-list dense nav>
      <span v-for="(item, i) in menu"
            :key="i" v-role="validationShowWithRol(item.id)">

        <v-list-group active-class="blueMinsal--text" v-if="item.childrens && item.childrens.length">
        <template v-slot:activator>
          <v-list-item-title class="text-capitalize">
            {{ item.nombre }}
          </v-list-item-title>
        </template>
        <template #prependIcon>
          <v-icon color="blueMinsal">{{ item.icono }}</v-icon>
        </template>
          <div>
            <v-list-item v-for="(child, j) in item.childrens" :key="j" :to="child.uri" active-class="blueMinsal--text">
              <v-list-item-icon>
                <v-icon>{{ child.icono }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-capitalize">
                <span>{{ child.nombre }}</span>
              </v-list-item-title>
            </v-list-item>
          </div>
      </v-list-group>
        <v-list-item v-else :to="item.uri"
                     active-class=" blueMinsal--text">
          <v-tooltip right :disabled="!sideBar" color="blueMinsal">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-icon v-bind="attrs" v-on="on">
                <v-icon>{{ item.icono }}</v-icon>
              </v-list-item-icon>
            </template>
            <span>{{ item.nombre }}</span>
          </v-tooltip>

        <v-list-item-title class="text-capitalize">
          {{ item.nombre }}
        </v-list-item-title>
        </v-list-item>
      </span>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "AppAsideBar",
  props: {
    menu: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    ...mapMutations("utils", ["setSideBar"]),
    validationShowWithRol(id) {
      if (id === 1) {
        return {
          action: 'hide',
          permission: 'ROL_REDES_LISTAR_INFORMES'
        }
      }
      if (id === 2) {
        return {
          action: 'hide',
          permission: 'ROL_PROVEEDOR_LISTAR_INFORMES'
        }
      }
      if(id === 3){
        return {
          action: 'hide',
          permission: 'ROL_INSTALACION_LISTAR_INFORMES'
        }
      }

      if(id === 4){
        return {
          action: 'hide',
          permission: "ROL_ACTAS_SIP_LISTAR_INFORMES"
        }
      }

      if(id === 5){
        return {
          action: 'hide',
          permission: "ROL_ACTAS_PROVEEDOR_LISTAR_INFORMES"
        }
      }

      if(id === 6){
        return {
          action: 'hide',
          permission: "ROL_REPORTE_MINED_LISTAR_INFORMES"
        }
      }

      if(id === 7){
        return {
          action: 'hide',
          permission: "ROL_SEGUIMIENTO_SIP_LISTAR_INFORMES"
        }
      }

      if(id === 8){
        return {
          action: 'hide',
          permission: "ROL_LISTAR_MEDIO_CONEXION"
        }
      }

      if(id === 9){
        return {
          action: 'hide',
          permission: "ROL_LISTAR_TIPO_MATERIAL"
        }
      }

      if(id === 10){
        return {
          action: 'hide',
          permission: "ROL_LISTAR_UNIDAD_MEDIDA"
        }
      }
    },
  },
  computed: {
    ...mapState("utils", ["sideBar"]),
  },
};
</script>

<style scoped></style>
