<template>
  <v-tabs :vertical="$vuetify.breakpoint.smAndDown"
          :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : ''" active-class="app-tabs-active" class="app-tabs"
          hide-slider background-color="transparent" color="3758f9" :grow="grow" :centered="centered" slider-size="3">
    <v-tab v-for="(tab, index) in tabs" :disabled="tab.disabled" :key="tab.title"
           @change="callInit(index)"
           class="app-tab text-capitalize font-weight-black"
           style="text-transform: none !important;">

      <div v-if="$vuetify.breakpoint.mdAndUp" class="text-no-style">
        <v-icon>{{ tab.icon }}</v-icon>
        {{ tab.title }}
      </div>

      <div v-else style="padding: auto; !important;">
        <p style="font-size:80% !important;" class="pt-3">
          <v-icon class="ma-0">{{ tab.icon }}</v-icon>
          <v-spacer v-if="$vuetify.breakpoint.smAndDown"></v-spacer>
          <span style="text-transform: none;">{{ tab.title }}</span>
        </p>
      </div>

    </v-tab>
    <v-tab-item  v-for="(tab, index) in tabs" :key="tab.title" class="app-tab-body" :eager="eager" v-if="tab.show">
      <component v-model="data" :data="data" :datosGenerales="datosGenerales" :rol="rol" :isView="isView" v-if="!tab.disabled" :ref="tab.ref"
                 :is="tab.component" class="py-8 px-3" :criterioId="criterioId">
        <template #actionSlot>
          <slot name="actionSlot"/>
        </template>
        <template #actionButon>
          <div>
            <slot name="actionButon"/>
          </div>
        </template>
      </component>
    </v-tab-item>
  </v-tabs>
</template>
<script>
export default {
  props: {
    tabs: {
      type: Array,
      default: () => [
        {
          title: "Informacion general",
          icon: "mdi-information-outline",
          component: "actividad",
          notification: false,
          contentNotification: 0,
        },
        {
          title: "Hoja de servicio",
          icon: "mdi-clipboard-text-outline",
          component: "formacion",
          notification: false,
          contentNotification: 0,
        },
        {
          title: "Fotografia",
          icon: "mdi-camera-outline",
          component: "conocimiento",
          notification: false,
          contentNotification: 0,
        },
      ],
    },
    datosGenerales: {
      type: Object,
      default: () => ({})
    },
    rol: {
      type: String,
      default: 'unidad'
    },
    sendSolicitud: {type: Function},
    isView: {
      type: Boolean,
      default: false
    },
    grow: {
      type: Boolean,
      default: true
    },
    centered: {
      type: Boolean,
      default: false
    },
    criterioId: {
      type: Number,
      default: 1,
    },
    eager: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    data: null,
  }),
  methods: {
    async callInit(indice) {
      const ref = this.tabs[indice].ref
      if (!!this.$refs[ref]) {
        const componentInfo = this.$refs[ref][0]
        if (!!componentInfo.init) {
          componentInfo.init()
        }
      }
    },
  },
}
</script>
