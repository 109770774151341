import Vue from "vue";
import "./plugins/axios";
import "./plugins/http_client";
import "./plugins/utils";
import "./plugins/security"
import App from "./App.vue";
import router from "./router";
import './services'
import store from "./store";
import vuetify from "./plugins/vuetify";
import vMask from "v-mask";
import "./assets/scss/variables.scss";
import roleDirective from './plugins/directiveRol'
import * as turf from "@turf/turf";
import momentTime from "moment-timezone";

import VCurrencyField from 'v-currency-field'
import {VTextField} from 'vuetify/lib' //Globally import VTextField
import InputFacade from 'vue-input-facade'
import {Icon} from 'leaflet'
import {LCircleMarker, LControl, LGeoJson, LMap, LMarker, LTileLayer, LTooltip, LControlZoom} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import loadComponentes from "@/plugins/loadComponents";
import Vuelidate from 'vuelidate'

Vue.prototype.$turf = turf

const moment = require("moment");
require("moment/locale/es");
Vue.prototype.moment = moment;

Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField)

Vue.use(InputFacade)

Vue.use(vMask);
Vue.use(require("vue-moment"), {
  moment,
  momentTime,
});

Vue.directive('role', roleDirective);

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-circle-marker', LCircleMarker);
Vue.component('l-tooltip', LTooltip);
Vue.component('l-geo-json', LGeoJson);
Vue.component('l-control', LControl);
Vue.component('l-control-zoom', LControlZoom);

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});


loadComponentes(Vue);

Vue.config.productionTip = false;

Vue.use(Vuelidate)

Vue.prototype.$eventBus = new Vue({
  router,
  store,
  vuetify,
  beforeCreate() {
    const token = localStorage.getItem("token");
    if (token) store.commit("setToken", token);
  },
  render: (h) => h(App),
}).$mount("#app");
