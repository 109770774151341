<template>
  <v-chip small :color="getColorState" class="text-caption text-truncate" :class="`${getColorTextState}--text`">
    {{ state.nombre }}
  </v-chip>
</template>
<script>
export default {
  name: "AppStateTable",
  props: {
    state: {
      type: Object,
      default: ()=> ({})
    },

  },
  computed: {
    getColorTextState() {
      if (this.state) {
        return this.textColorSoliState[this.state.id];
      }
    },
    getColorState() {
      if (this.state) {
        return this.colorSoliState[this.state.id];
      }
    }
  },
  data() {
    return {
      colorSoliState: {
        8: "stateObservado",
        9: "stateSolventadoRGB",
        10: "stateAprobado",
        11: "stateAprobado",
        12: "stateEnBorrador",
        13: "stateAprobado",
        14: "stateAprobado",
      },
      textColorSoliState: {
        8: "textObservado",
        9: "textSolventado",
        10: "textAprobado",
        11: "textAprobado",
        12: "textEnBorrador",
        13: "textAprobado",
        14: "textAprobado"
      }
    };
  }
};
</script>
<style scoped lang="scss">
.stateSolventadoRGB {
  color: var(--v-stateSolventado-base) !important;
}

.stateInactivoRgb {
  color: var(--v-stateInactivo-base) !important;
}
</style>
