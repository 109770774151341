import {http_client} from "@/plugins/http_client";

const url = 'public';

const listarSitios = async (params = {}) => await http_client(`/${url}/sitios`, params, 'get')

const listarEstados = async (data) => await http_client(`/${url}/proveedor/estados_informe`, data, 'get')
const tipositio = async (params = {}) => await http_client(`/${url}/tipo-sitio/obtener-tipos-sitios`, params, 'get')
const sitioCreado = async (data) => await http_client(`/${url}/tipo-sitio/crear-tipo-sitio`, data, 'post')
const editSitio = async (data, id) => await http_client(`/${url}/tipo-sitio/actualizar-tipo-sitio/${id}`, data, 'put')
const cambiarEstado = async (data,id) => await http_client(`/${url}/tipo-sitio/actualizar-estado-tipo-sitio/${id}`, data, 'put')
const getTiposArchivos = async (params = {}) =>await http_client(`/${url}/tipo-archivo/obtener-tipos-archivos`, params, 'get')
const postTiposArchivos = async (data) =>await http_client(`/${url}/tipo-archivo/crear-tipo-archivo`, data, 'post')
const putTiposArchivos = async (data, id) =>await http_client(`/${url}/tipo-archivo/actualizar-tipo-archivo/${id}`, data, 'put')
const changeState = async (data, id) => await http_client(`/${url}/tipo-archivo/actualizar-estado-tipo-archivo/${id}`, data, 'put')
const getInfraestructura = async (params = {}) => await http_client(`/${url}/tipo-infraestructura/obtener-tipo-infraestructuras`, params, 'get')
const postInfraestructura = async (data) => await http_client(`/${url}/tipo-infraestructura/crear-tipo-infraestructura`, data, 'post')
const putInfraestructura = async (data, id) => await http_client(`/${url}/tipo-infraestructura/actualizar-tipo-infraestructura/${id}`, data, 'put')
const putChangeState = async (data, id) => await http_client(`/${url}/tipo-infraestructura/actualizar-estado-tipo-infraestructura/${id}`, data, 'put')
const getTiposMateriales = async (params = {}) => await http_client(`/${url}/tipo-material/obtener-tipos-materiales`, params, 'get')
const postTiposMateriales = async (data) => await http_client(`/${url}/tipo-material/crear-tipo-material`, data, 'post')
const putTiposMateriales = async (data, id) => await http_client(`/${url}/tipo-material/actualizar-tipo-material/${id}`, data, 'put')
const putActualizarMateriales = async (data, id) => await http_client(`/${url}/tipo-material/actualizar-estado-tipo-material/${id}`, data, 'put')
const getUnidadMedida = async (params = {}) => await http_client(`/${url}/unidad-medida/obtener-unidades-medida`, params, 'get')
const postUnidadMedida = async (data) => await http_client(`/${url}/unidad-medida/crear-unidad-medida`, data, 'post')
const putUnidadMedida = async (data, id) => await http_client(`/${url}/unidad-medida/actualizar-unidad-medida/${id}`, data, 'put')
const putActualizarUnidadMedida = async (data, id) => await http_client(`/${url}/unidad-medida/actualizar-estado-unidad-medida/${id}`, data, 'put')
const getMediosConexion = async (params = {}) => await http_client(`/${url}/medio-conexion/obtener-medios-conexion`, params, 'get')
const postMediosConexion = async (data) => await http_client(`/${url}/medio-conexion/crear-medio-conexion`, data, 'post')
const putMediosConexion = async (data, id) => await http_client(`/${url}/medio-conexion/actualizar-medio-conexion/${id}`, data, 'put')
const putActualizarMediosConexion = async (data, id) => await http_client(`/${url}/medio-conexion/actualizar-estado-medio-conexion/${id}`, data, 'put')
const getUsuarios = async (params = {}) => await http_client(`/${url}/gestion-usuarios/obtener-usuarios`, params, 'get')
const postUsuarios = async (data) => await http_client(`/${url}/gestion-usuarios/crear-usuario`, data, 'post')
const changeUserState = async (data, id) => await http_client(`/${url}/gestion-usuarios/actualizar-estado-usuario/${id}`, data, 'put')
const getsitios = async (params = {}) => await http_client(`/${url}/gestion-sitios/obtener-sitios`, params, 'get')
const changeSitioState = async (data, id) => await http_client(`/${url}/gestion-sitios/actualizar-estado-sitio/${id}`, data, 'put')
export default {
	listarSitios,
	listarEstados,
	tipositio,
	sitioCreado,
	editSitio,
	cambiarEstado,
	getTiposArchivos,
	postTiposArchivos,
	putTiposArchivos,
	changeState,
	getInfraestructura,
	postInfraestructura,
	putInfraestructura,
	putChangeState,
	getTiposMateriales,
	postTiposMateriales,
	putTiposMateriales,
	putActualizarMateriales,
	getUnidadMedida,
	postUnidadMedida,
	putUnidadMedida,
	putActualizarUnidadMedida,
	getMediosConexion,
	postMediosConexion,
	putMediosConexion,
	putActualizarMediosConexion,
	getUsuarios,
	changeUserState,
	postUsuarios,
	getsitios,
	changeSitioState
}
