import {http_client} from "@/plugins/http_client";

const url = 'public';

const listarInformes = async (params = {}) => await http_client(`/${url}/proveedor`, params, 'get')
const agregarInforme = async (params = {}) => await http_client(`/${url}/proveedor/informe`, params, 'post')
const actualizarInforme = async (id, params = {}) => await http_client(`/${url}/proveedor/informe/${id}`, params, 'put')
const detalleInforme = async (id_informe, body) => await http_client(`/${url}/proveedor/detalle-general/${id_informe}`, body, 'get')
const eliminarInforme = async (id, params = {}) => await http_client(`/${url}/proveedor/informe/${id}`, params, 'put')
const getItemsObservaciones = async (id_informe, params) => await http_client(`/${url}/proveedor/observaciones/${id_informe}`, params, 'get')
const updateObservacion = async (id_informe, params = {}) => await http_client(`/${url}/proveedor/actualizarObservacion/${id_informe}`, params, 'put')
const getInforme =async (data) => await http_client(`/${url}/actas-proveedor`, data , 'get')
const getDepartamento = async () => await http_client(`/${url}/departamento`, {}, 'get')
const getMunicipio = async (data) => await http_client(`/${url}/municipio`, data , 'get')
const getDistrito = async (data) => await http_client(`/${url}/distrito`, data , 'get')
const getSitio = async (data) => await http_client(`/${url}/sitios`, data, 'get')
const getReporte = async (data) => await http_client(`/${url}/reportes/sip`, data , 'get')

const getImageById = async (id_archivo, params = {}) => await http_client(`${url}/redes/seguimiento/obtener-imagen-id/${id_archivo}`, params);
const getReporteEntrega = async (data) => await http_client(`/${url}/reportes/proveedor`, data , 'get')

export default {
  listarInformes,
  agregarInforme,
  detalleInforme,
  eliminarInforme,
  actualizarInforme,
  getItemsObservaciones,
  updateObservacion,
  getInforme,
  getDepartamento,
  getMunicipio,
  getDistrito,
  getSitio,
  getImageById,
  getReporte,
  getReporteEntrega
}
