<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" @click="action" icon>
          <v-icon :color="iconColor">{{ icon }}</v-icon>
        </v-btn>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "ActionIconComponent",
  props: {
    icon: {
      type: String,
      required: true,
    },
    iconColor: {
      type: String,
      default: "grey darken-2",
    },
    tooltip: {
      type: String,
      required: true,
    },
    enabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    action() {
      this.$emit("onClick");
    },
  },
};
</script>
