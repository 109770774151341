import {http_client} from "@/plugins/http_client";

const url = 'public';

const getInformeInstalacion = async (params = {}) => await http_client(`/${url}/instalacion`, params, 'get')
const postInformeInstalacion = async (params = {}) => await http_client(`/${url}/instalacion/informe`, params, 'post')
const getDetalleInforme = async (id_informe, body) => await http_client(`/${url}/instalacion/detalle-general/${id_informe}`, body, 'get')
const putInformeInstalacion = async (id_informe, body) => await http_client(`/${url}/instalacion/detalle-general/${id_informe}`, body, 'put')
// Detalle informe instalacion

const getDetalleInformeInstalacion = async (id_informe, data) => await http_client(`/${url}/instalacion/detalle-informe/listado/${id_informe}`, data, 'get')
const postDetalleInformeInstalacion = async (data) => await http_client(`/${url}/instalacion/detalle-informe`, data, 'post')
const putDetalleInformeInstalacion = async (id_detalle, data) => await http_client(`/${url}/instalacion/detalle-informe/${id_detalle}`, data, 'put')
const getDetalleInformeInstalacionById = async (id_detalle, data) => await http_client(`/${url}/instalacion/detalle-informe/ver/${id_detalle}`, data, 'get')
const deleteDetalleInformeInstalacion = async (id_detalle, data) => await http_client(`/${url}/instalacion/detalle-informe/eliminar/${id_detalle}`, data, 'delete')

// infraestructura

const getInfraestructura = async (id_informe, data) => await http_client(`/${url}/instalacion/detalle-infraestructura/listado/${id_informe}`, data, 'get')
const getInfraestructuraById = async (id_detalle, data) => await http_client(`/${url}/instalacion/detalle-infraestructura/${id_detalle}`, data, 'get')
const getTipoInfraestructura = async (data) => await http_client(`/${url}/infraestructura`, data, 'get')
const getEstadoInfraestructura = async (data) => await http_client(`/${url}/infraEstructuraExistente`, data, 'get')
const postInfraestructura = async (data) => await http_client(`/${url}/instalacion/detalle-infraestructura`, data, 'post')
const putInfraestructura = async (id_detalle, data) => await http_client(`/${url}/instalacion/detalle-infraestructura/${id_detalle}`, data, 'post')
const deleteInfraestructura = async (id_detalle, data) => await http_client(`/${url}/instalacion/detalle-infraestructura/${id_detalle}`, data, 'delete')

const deleteImgAnchoBanda = async (id_archivo, data) => await http_client(`/${url}/instalacion/archivos/${id_archivo}`, data, 'delete')
export default {
  getInformeInstalacion,
  postInformeInstalacion,
  getDetalleInforme,
  getDetalleInformeInstalacion,
  postDetalleInformeInstalacion,
  putDetalleInformeInstalacion,
  getDetalleInformeInstalacionById,
  deleteDetalleInformeInstalacion,
  getInfraestructura,
  getTipoInfraestructura,
  getEstadoInfraestructura,
  postInfraestructura,
  getInfraestructuraById,
  putInfraestructura,
  deleteInfraestructura,
  putInformeInstalacion,
  deleteImgAnchoBanda
}
