import {http_client} from "@/plugins/http_client";

const url = 'public';

const getReportesMINED = async (params = {}) => await http_client(`/${url}/reportes-mined`, params, 'get')
const getStateReportes = async (params = {}) => await http_client(`/${url}/estados-reportes`, params, 'get')
export default {
  getReportesMINED,
  getStateReportes
}
