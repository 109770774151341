export const redesRoutes = [
  {
    path: "/equipo-redes",
    name: "equipo-redes",
    meta: { rol: "ROL_REDES_LISTAR_INFORMES" },
    component: () =>
      import(/* webpackChunkName: "equipo-redes" */ "../index.vue"),
  },
  {
    path: "/detalle-redes/:id",
    name: "detalleRedes",
    meta: { rol: "ROL_REDES_VER_DETALLE_GENERAL" },
    component: () =>
      import(/* webpackChunkName: "detalle-redes" */ "../views/NetworkDetail.vue"),
  },
  {
    path: "/informe-redes/:id_informe/proveedor/:id_module",
    name: "informeRedesProveedor",
    meta: { rol: "ROL_REDES_VER_SEGUIMIENTO_CRITERIOS" },
    component: () =>
      import(/* webpackChunkName: "detalle-redes" */ "../views/InformeProveedor.vue"),
  },
  {
    path: "/informe-redes/:id_informe/instalador/:id_module",
    name: "informeRedesInstalador",
    meta: { rol: "ROL_REDES_VER_SEGUIMIENTO_CRITERIOS" },
    component: () =>
        import(/* webpackChunkName: "detalle-redes" */ "../views/InformeProveedor.vue"),
  },
]
