<template>
  <section>
    <v-card>
      <!-- table -->
      <v-data-table
          ref="table"
          class="sticky-table"
          :headers="headers"
          :items-per-page="per_page"
          :items="items"
          hide-default-footer
          :loading="loading"
          :mobile-breakpoint="mobileBreakpoint"
      >
        <!-- slots -->
        <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
          <slot :name="name" v-bind="data" v-if="name"></slot>
        </template>
      </v-data-table>

      <!-- pagination -->
      <v-card-text>
        <div class="d-flex flex-column flex-md-row justify-center align-center">
          <div class="d-flex flex-row mx-2">
            <span class="pt-1 pr-2" v-text="'Filtrar por página:'"/>
            <div class="select_pag">
              <v-select
                  :disabled="totalRows == 0"
                  class="custom-select"
                  hide-details
                  dense
                  :items="ctlPerPage"
                  color="blueDarkMinsal"
                  item-color="primary"
                  append-icon="mdi-chevron-down"
                  v-model="per_page"
              />
            </div>
          </div>

          <div class="mx-2 d-flex flex-column flex-md-row align-center">
            <v-btn
                text
                class="text-no-style"
                :disabled="page == 1 || totalRows == 0"
                @click="page--"
            >
              <span v-text="'Anterior'"/>
            </v-btn>

            <v-pagination
                class="px-1"
                :style="{ scale: CalcSizeFn(290) ? 0.85 : 1 }"
                :total-visible="totalVisibleCd"
                color="blueDarkMinsal"
                v-model="page"
                :length="pagesCd ?? 0"
            />

            <v-btn
                text
                class="text-no-style"
                :disabled="page == pagesCd || totalRows == 0"
                @click="page++"
            >
              <span v-text="'Siguiente'"/>
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
export default {
  name: "AppTableComponent",

  props: {
    headers: {
      type: Array,
      required: true,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => ([]),
    },
    loading: {
      type: Boolean,
      default: false,
    },

    ctlPerPage: {
      type: Array,
      default: () => [5, 10, 25, 100],
    },

    totalRows: {
      type: Number,
      required: true,
    },
    mobileBreakpoint: {
      type: Number,
      default: 600,
    },
    sticky: {
      type: Boolean,
      default: false,
    },
    setPage: {
      type: Number,
      default: null,
    }
  },

  data() {
    return {
      page: this.setPage ?? 1,
      per_page: 10,
    }
  },

  computed: {
    pagesCd() {
      return Math.ceil(this.totalRows / this.per_page);
    },

    totalVisibleCd() {
      const screens = [
        {device: this.$vuetify.breakpoint.smAndUp, visible: 10},
        {device: this.$vuetify.breakpoint.xsOnly, visible: 5},
      ];
      const screen = screens.find((screen) => screen.device);
      return screen.visible;
    },
  },

  methods: {
    CalcSizeFn(width) {
      const widthScreen = this.$vuetify.breakpoint.width;
      return widthScreen <= width;
    },

    // express functions
    refresh() {
      this.$emit("refresh", {
        page: this.page,
        per_page: this.per_page,
      });
    },
    reset() {
      this.page = 1;
      this.per_page = 10;
    },

    // setLastColumnWidth() {
    //   this.$nextTick(() => {
    //     const table = this.$el.querySelector(".v-data-table");
    //     const lastColumnCells = table.querySelectorAll(
    //         "td:nth-last-child(1), th:nth-last-child(1)"
    //     );
    //     if (lastColumnCells.length > 0) {
    //       const lastColumnWidth = lastColumnCells[0].offsetWidth;
    //       document.documentElement.style.setProperty(
    //           "--last-column-width",
    //           `${lastColumnWidth}px`
    //       );
    //     }
    //     // Repite para las demás columnas
    //   });
    // },
  },

  watch: {
    page(value) {
      this.$emit("refresh", {
        page: value,
        per_page: this.per_page,
      });
    },
    per_page(value) {
      this.page = 1;
      this.$emit("refresh", {
        page: this.page,
        per_page: value,
      });
    },
    setPage(value) {
      this.page = value;
    },
    // items() {
    //   if (this.sticky) {
    //     this.setLastColumnWidth();
    //   }
    // },
  },
};
</script>
<style lang="scss" scoped>
:root {
  --last-column-width: 0px;
}

::v-deep .v-pagination__item {
  border-radius: 10px !important;
}

.custom-select {
  max-width: min-content;
}

::v-deep .v-data-table-header th {
  color: #fff !important;
  background-color: #3758f9 !important;
}

//::v-deep .sticky-table {
//  .sticky-header,
//  .sticky-column {
//    position: sticky;
//    right: 0;
//  }
//
//  .sticky-column {
//    background-color: #fff;
//  }
//
//  .shadow-header::before {
//    content: "";
//    position: absolute;
//    top: 0;
//    bottom: 0;
//    left: -2px;
//    width: 3px;
//    background: linear-gradient(
//            to right,
//            rgba(255, 255, 255, 0.1),
//            rgba(255, 255, 255, 0)
//    );
//  }
//
//  .shadow-column::before {
//    content: "";
//    position: absolute;
//    top: 0;
//    bottom: 0;
//    left: -2px;
//    width: 3px;
//    background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
//  }
//
//  th:last-child.sticky-header,
//  td:last-child.sticky-column {
//    right: 0px;
//  }
//
//  th:nth-last-child(2).sticky-header,
//  td:nth-last-child(2).sticky-column {
//    right: var(--last-column-width);
//  }
//
//  th:nth-last-child(3).sticky-header,
//  td:nth-last-child(3).sticky-column {
//    right: calc(var(--last-column-width) * 2 + 10px);
//  }
//}
</style>
