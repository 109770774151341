<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" v-if="showSitio">
        <h5>Sitio</h5>
        <v-autocomplete
            v-model="filters.id_sitio"
            :items="sitios"
            item-text="nombre"
            item-value="id"
            label="Seleccionar"
            dense
            outlined
            clearable
            @click:clear="getSelectSitio"
            :search-input.sync="searchSitio"
            @keyup="buscarSitio"
            @change="changeSitio"
            return-object
        ></v-autocomplete>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" :sm="sm" :md="md" v-if="showDepartamento">
        <h5>Departamento</h5>
        <v-autocomplete
            v-model="filters.id_departamento"
            :items="departamentos"
            item-text="nombre"
            item-value="id"
            label="seleccionar"
            dense
            outlined
            clearable
            @click:clear="clearableDepartamento"
            :disabled="disabledDep"
            @change="getSelectMunicipio"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" :sm="sm" :md="md" v-if="showMunicipio">
        <h5>Municipio</h5>
        <v-autocomplete
            v-model="filters.id_municipio"
            :items="municipios"
            item-text="nombre"
            item-value="id"
            label="Seleccionar"
            dense
            outlined
            clearable
            @click:clear="filters.id_distrito = null"
            :disabled="disabledDep || !filters.id_departamento"
            @change="getSelectDistrito"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" :sm="sm" :md="md" v-if="showDistrito">
        <h5>Distrito</h5>
        <v-autocomplete
            v-model="filters.id_distrito"
            :items="distritos"
            item-text="nombre"
            item-value="id"
            label="Seleccionar"
            dense
            outlined
            clearable
            :disabled="disabledDep || !filters.id_municipio"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4">
        <v-sheet class="style--v-sheet" :style="{backgroundColor: filters.id_sitio?.hasOwnProperty('id') ?  'rgba(251, 191, 36, .4)' : '#FBBF24'}">
          <v-checkbox
              v-model="estado.sinIntervencion"
              color="white"
              class="no-border-checkbox"
              :disabled="filters.id_sitio?.hasOwnProperty('id')"
              label="Sin intervanción"
          ></v-checkbox>
        </v-sheet>
      </v-col>
      <v-col cols="12" sm="4">
        <v-sheet class="style--v-sheet"  :style="{backgroundColor: filters.id_sitio?.hasOwnProperty('id') ?  'rgba(55, 88, 249, .4)' : '#3758F9'}" >
          <v-checkbox
              v-model="estado.enIntervencion"
              color="white"
              class="no-border-checkbox"
              :disabled="filters.id_sitio?.hasOwnProperty('id')"
              label="En intervanción"></v-checkbox>
        </v-sheet>
      </v-col>
      <v-col cols="12" sm="4">
        <v-sheet class="style--v-sheet" :style="{backgroundColor: filters.id_sitio?.hasOwnProperty('id') ?  'rgba(19, 194, 150, .4)' : '#13C296'}" >
          <v-checkbox
              v-model="estado.conectado"
              color="white"
              class="no-border-checkbox"
              :disabled="filters.id_sitio?.hasOwnProperty('id')"
              label="Conectado"></v-checkbox>
        </v-sheet>
      </v-col>

    </v-row>
    <v-row class="justify-center">
      <v-col cols="12" class="d-flex justify-center" style="gap: 5px">
        <v-btn
            color="primary"
            class="text-capitalize pa-6"
            @click="buscarInforme"
        >
          {{ btnTextConfirm }}
        </v-btn>

        <v-btn
            color="grey darken-1"
            outlined
            class="text-capitalize pa-6"
            @click="limpiarFilros"
        >
          {{ btnTextLimpiar }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import AppStateTableReport from "@/components/AppSatateTableReport.vue";

export default {
  name: "filtroComponent",
  components: {AppStateTableReport},
  props: {
    executeClean: {
      type: Function,
      default: () => {
      }
    },
    showDepartamento: {
      type: Boolean,
      default: true
    },
    showMunicipio: {
      type: Boolean,
      default: true
    },
    showDistrito: {
      type: Boolean,
      default: true
    },
    showFechaDesde: {
      type: Boolean,
      default: true
    },
    showFechaHasta: {
      type: Boolean,
      default: true
    },
    showEstado: {
      type: Boolean,
      default: false
    },
    showSitio: {
      type: Boolean,
      default: true
    },
    sm: {
      type: String,
      default: '6'
    },
    md: {
      type: String,
      default: '4'
    },
    btnTextConfirm: {
      type: String,
      default: 'Buscar'
    },
    btnTextLimpiar: {
      type: String,
      default: 'Limpiar'
    },
  },
  data() {
    return {
      filters: {},
      estado: {
        sinIntervencion: false,
        enIntervencion: false,
        conectado: false
      },
      disabledDep: false,
      fechaDesde: null,
      fechaHasta: null,
      searchSitio: null,
      sitios: [],
      departamentos: [],
      municipios: [],
      distritos: [],
      timeId: null,
      estados: [],
      desdeDate: false,
      hastaDate: false
    }
  },
  methods: {
    async changeSitio(value) {
      if (value) {
        this.filters = {
          ...this.filters,
          id_departamento: null,
          id_municipio: null,
          id_distrito: null
        }
        const res = await this.getSelectMunicipio(value.id_departamento)
        if (res.hasOwnProperty('status') && res?.status === 200) {
          const resp = await this.getSelectDistrito(value.id_municipio)
          if (resp.hasOwnProperty('status') && resp?.status === 200) {
            this.$set(this.filters, 'id_departamento', value.id_departamento)
            this.$set(this.filters, 'id_distrito', value.id_distrito)
            this.$set(this.filters, 'id_municipio', value.id_municipio)
          }
        }

        this.disabledDep = true
      } else {
        this.filters = {
          ...this.filters,
          id_departamento: null,
          id_municipio: null,
          id_distrito: null
        }
        this.disabledDep = false
      }
    },

    buscarInforme() {
      this.$emit('buscar', {
        filters: {...this.filters},
        estado: {...this.estado},
      })
    },
    async limpiarFilros() {
      this.filters = {}
      this.estado = {
        sinIntervencion: false,
        enIntervencion: false,
        conectado: false
      }
      await this.changeSitio(null)
      await this.executeClean()
    },
    buscarSitio() {
      this.timeId && clearTimeout(this.timeId)
      this.timeId = setTimeout(() => {
        this.getSelectSitio({nombre: this.searchSitio})
      }, 500)
    },
    async getSelectSitio(filters = {}) {
      try {
        const {data, status} = await this.services.proveedor.getSitio(filters);
        if (status === 200) {
          this.sitios = data.data.map((sitio) => ({
            ...sitio,
            id: sitio.id,
            nombre: `${sitio.codigo} - ${sitio.nombre}`,
          }));
        }
      } catch (error) {
        console.error("Error al obtener sitios", error);
      }
    },
    async getSelectDistrito(id_municipio) {
      try {
        const {data, status} = await this.services.proveedor.getDistrito({
          id_municipio,
        });

        if (status === 200) {
          this.distritos = data.data.map((distrito) => ({
            id: distrito.id,
            nombre: distrito.nombre,
          }));

          return {status}
        }
      } catch (error) {
        console.error("Error al obtener sitios", error);
      }
    },
    async getSelectMunicipio(id_departamento) {

      try {
        const {data, status} = await this.services.proveedor.getMunicipio({
          id_departamento,
        });

        if (status === 200) {
          this.municipios = data.data
          return {status}
        }
      } catch (error) {

        console.error("Error al obtener sitios", error);
        return {status: 500}
      }
    },
    async getSelectDepartamento() {
      try {
        const {data, status} =
            await this.services.proveedor.getDepartamento();

        if (status === 200) {
          this.departamentos = data.data
          return {status}
        }
      } catch (error) {
        console.error("Error al obtener sitios", error);
        return {status: 500}
      }
    },
    clearableDepartamento(){
      this.filters.id_municipio = null
      this.filters.id_distrito = null
    }
  },
  async created() {

    await Promise.all([
      this.getSelectSitio(),
      this.getSelectDepartamento(),
    ]);
  }
}
</script>
<style lang="scss" scoped>
.style--v-sheet {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: 50px;
}

.no-border-checkbox {
  color: white;

  ::v-deep .v-input__slot {
    border: none !important;

    .v-icon {
      color: #ffffff !important;

    }

    label {
      color: white;
    }
  }
}
</style>
