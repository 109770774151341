import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '../assets/scss/index.scss'
import es from "vuetify/lib/locale/es";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: {es},
    current: "es",
  },
  options: {
    customProperties: true
  },
  theme: {
    themes: {
      //Se definen colores globales para el tema claro
      light: {
        blueMinsal: '#175EFB',
        blueGrayMinsal: '#6A83BE',
        blueDarkMinsal: '#2D52A8',
        bgMinsal: '#F4F7FD',
        bgWhite: "#FFFFFF",
        primary: '#175EFB',

        boldBlue: '#223E69',
        mediumBlue: '#102C99',
        lightBlue: '#3758F9',

        checkState: '#13C296',
        textTable: '#637381',
        iconObservacion: '#FBBF24',
        stateBorrador: '#E5E7EB80',
        textBorrador: '#374151',
        stateObservado: '#FFFBEB',
        textObservado: '#D97706',
        stateEnviado: '#D0F0FD',
        textEnviado: '#0B76B7',
        stateSolventado: '#4361FF1A',
        textSolventado: '#3758F9',
        stateAprobado: '#DAF8E6',
        textAprobado: '#1A8245',
        stateInactivo: '#C414141A',
        tetInactivo: '#C41414'
      },
      dark: {
        bgMinsal: '#2f3032',
        blueMinsal: '#175EFB',
        blueGrayMinsal: '#6A83BE',
        blueDarkMinsal: '#2D52A8',
        primary: '#175EFB'
        // primary: {
        //     base: colors.purple.base,
        // }
      }
    }
  }
});
