import {http_client} from "@/plugins/http_client";
const url = 'public';

const listarRedes = async (params = {}) => await http_client(`/${url}/redes`, params, 'get');
const detalleRedes = async (sitio_id) => await http_client(`/${url}/redes/detalle-general/${sitio_id}`, {}, 'get');
const estatusCriterioProveedores = async (id_informe, params ={}) => await http_client(`/${url}/redes/seguimiento/criterios/${id_informe}`, params);
const listSSID = async (id_informe, params ={}) => await http_client(`/${url}/redes/seguimiento/lista-ap/${id_informe}`, params, 'get');
const createSeguimiento = async (params ={}) => await http_client(`/${url}/redes/seguimiento`, params, 'post');
const postAdjuntos = async (body) => await http_client(`/public/redes/seguimiento/archivos`, body, 'post');
const postObservaciones = async (body) => await http_client(`/public/redes/seguimiento/observacion`, body, 'post');
const crearSeguimiento = async (params = {}) => await http_client(`/${url}/public/redes/seguimiento`, params, 'post');
const crearCriterioSeguimiento = async (params = {}) => await http_client(`/${url}/redes/seguimiento`, params, 'post');
const agregarSSID = async (params = {}) => await http_client(`${url}/redes/seguimiento/crear-ap`, params, 'post');
const listarSSIDInforme = async (informe_id, params = {}) => await http_client(`${url}/redes/seguimiento/lista-ssid/${informe_id}`, params, 'get');
const confirmarSSIDap = async (ssid_id, params = {}) => await http_client(`${url}/redes/seguimiento/activar-ap/${ssid_id}`, params, 'put'); //criterio 8
const confirmarSSID = async (ssid_id, params = {}) => await http_client(`${url}/redes/seguimiento/activar-ssid/${ssid_id}`, params, 'put'); //criterio 9
const getObservaciones = async (id_seguimiento) =>  await http_client(`/public/redes/seguimiento/observacion/${id_seguimiento}`, null, 'get');

// resumen sitio
const getResumenSitio = async (id_informe, params = {}) => await http_client(`public/redes/seguimiento/lista-criterios/${id_informe}`, params, 'get');

// get list images
const getImages = async (id_seguimiento, params = {}) => await http_client(`public/redes/seguimiento/lista-imagenes/${id_seguimiento}`, params);
const getImageById = async (id_archivo, params = {}) => await http_client(`${url}/redes/seguimiento/obtener-imagen-id/${id_archivo}`, params);
// change status
const changeStatus = async (id_informe, id_estado, params = {}) => await http_client(`public/redes/seguimiento/aprobar-observar-informe/${id_informe}/${id_estado}`, params, 'put');
const listaBlanca = async(params = {}) => await http_client(`/${url}/redes/seguimiento/agregar-lista-blanca`, params, 'post');
const listaNegra = async(params = {}) => await http_client(`/${url}/redes/seguimiento/agregar-lista-negra`, params, 'post');
const cargaInicialLista = async(id_informe,id_seguimiento,tipo,params = {}) => await http_client(`/${url}/redes/seguimiento/lista-blanca-negra/${id_informe}/${id_seguimiento}/${tipo}`, params, 'get');
const confirmarLista = async (lista_id, params = {}) => await http_client(`${url}/redes/seguimiento/actualizar-estado-lista/${lista_id}`, params, 'put');
const deleteObservacion = async (id) => await http_client(`/public/redes/seguimiento/observacion/eliminar/${id}`, {}, 'put');

const getSeguimiento = async (params = {}) => await http_client(`/${url}/redes/seguimiento/criterios/getId`, params, 'get');
const deleteAdjuntos = async (id_archivo, params = {}) => await http_client(`/${url}/redes/seguimiento/eliminar-archivo/${id_archivo}`, params, 'delete');
//lista de imagenes
const postImagesStep = async (body) => await http_client(`/public/instalacion/archivos`, body, 'post');
const getImagesStep = async (params = {id_informe, id_tipo_archivo}) => await http_client(`/public/instalacion/archivos`, params, 'get');
export default {
	getImagesStep,
	postImagesStep,
	listarRedes,
	detalleRedes,
	estatusCriterioProveedores,
	listSSID,
	postAdjuntos,
	postObservaciones,
	agregarSSID,
	crearSeguimiento,
	getResumenSitio,
	createSeguimiento,
	crearCriterioSeguimiento,
	listarSSIDInforme,
	confirmarSSIDap,
	confirmarSSID,
	getImages,
	changeStatus,
	getObservaciones,
	getSeguimiento,
	listaBlanca,
	listaNegra,
	cargaInicialLista,
	confirmarLista,
	deleteObservacion,
	deleteAdjuntos,
	getImageById
}
